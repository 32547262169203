@charset "UTF-8";
/*-------------------------
  theme colors
-------------------------*/
/* primary
-------------------------*/
/* secondary
-------------------------*/
/* error
-------------------------*/
/*-------------------------
  common color
-------------------------*/
@font-face {
  font-family: "Noto Sans JP";
  src: url("/assets/fonts/jp/NotoSansJP-Regular.woff2") format("woff2"), url("/assets/fonts/jp/NotoSansJP-Regular.woff") format("woff"), url("/assets/fonts/jp/NotoSansJP-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: "Noto Sans JP";
  src: url("/assets/fonts/jp/NotoSansJP-Bold.woff2") format("woff2"), url("/assets/fonts/jp/NotoSansJP-Bold.woff") format("woff"), url("/assets/fonts/jp/NotoSansJP-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
/* normal-italic*/
@font-face {
  font-family: "Noto Sans JP";
  src: url("/assets/fonts/jp/NotoSansJP-Regular.woff") format("woff2"), url("/assets/fonts/jp/NotoSansJP-Regular.woff") format("woff"), url("/assets/fonts/jp/NotoSansJP-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/en/Roboto-Regular.woff2") format("woff2"), url("/assets/fonts/en/Roboto-Regular.woff") format("woff"), url("/assets/fonts/en/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/en/Roboto-Bold.woff2") format("woff2"), url("/assets/fonts/en/Roboto-Bold.woff") format("woff"), url("/assets/fonts/en/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
/* normal-italic */
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/en/Roboto-Italic.woff") format("woff2"), url("/assets/fonts/en/Roboto-Italic.woff") format("woff"), url("/assets/fonts/en/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("/assets/fonts/en/Raleway-Regular.woff2") format("woff2"), url("/assets/fonts/en/Raleway-Regular.woff") format("woff"), url("/assets/fonts/en/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* light */
@font-face {
  font-family: "Raleway";
  src: url("/assets/fonts/en/Raleway-Light.woff2") format("woff2"), url("/assets/fonts/en/Raleway-Light.woff") format("woff"), url("/assets/fonts/en/Raleway-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: "Raleway";
  src: url("/assets/fonts/en/Raleway-Bold.woff2") format("woff2"), url("/assets/fonts/en/Raleway-Bold.woff") format("woff"), url("/assets/fonts/en/Raleway-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Arizonia";
  src: url("/assets/fonts/en/Arizonia-Regular.woff2") format("woff2"), url("/assets/fonts/en/Arizonia-Regular.woff") format("woff"), url("/assets/fonts/en/Arizonia-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -1.6rem;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0);
  }
}
@keyframes rippleRev {
  0% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
}
#video-area {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1; /*最背面に設定*/
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
#video-area::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 0.01%, rgba(0, 0, 0, 0.1) 30.33%);
}

#video {
  /*天地中央配置*/
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*縦横幅指定*/
  width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
  height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
  min-height: 100%;
  min-width: 100%;
}

.top-box {
  padding: 1.6rem;
  background: linear-gradient(90.42deg, #fffdd6 0%, #f5fafc 100%);
  border-radius: 1.2rem;
  margin: 0 0.4rem 0.4rem;
}
@media screen and (min-width: 1024px) {
  .top-box {
    margin: 0 1.2rem 0.4rem;
    padding: 3rem;
    border-radius: 3rem;
  }
}
.top-box .top-btn-list {
  display: grid;
  line-height: 1;
}
.top-box .top-btn-list a {
  padding: 1.7rem 0;
  display: block;
  border: 1px solid #333;
  border-radius: 0.8rem;
  background-color: #fff;
  overflow: hidden;
  letter-spacing: 0.07em;
}
@media screen and (min-width: 1226px) {
  .top-box .top-btn-list a {
    padding: 2.7rem 5rem 2.7rem 14px;
    border-radius: 1rem;
    position: relative;
  }
  .top-box .top-btn-list a::after {
    content: "\e915";
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    font-family: "potaicon";
    background-color: #fff55e;
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .top-box--head {
    padding: 3rem 8.4rem;
  }
}
.top-box--head .top-btn-list {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1;
}
@media screen and (min-width: 1024px) {
  .top-box--head .top-btn-list {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1.2rem;
    font-size: 1.6rem;
  }
}

.top-title--en {
  font-family: "Raleway", sans-serif;
}

@media screen and (min-width: 1024px) {
  .top-inner {
    max-width: calc(1200px + 2.4rem);
    margin: 0 auto;
    padding: 0 1.2rem;
  }
}

.top-hero {
  height: 100vh;
  position: relative;
  z-index: 2;
}
.top-hero .top-hero-inner {
  height: 100vh;
  margin: 0;
  border: 0.4rem solid #fff;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .top-hero .top-hero-inner {
    border-width: 1.2rem;
  }
}
.top-hero .top-hero-content {
  width: 100%;
  padding: 0 2.7rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-30%);
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .top-hero .top-hero-content {
    padding: 0 7rem;
  }
}
.top-hero .top-hero-title {
  margin-bottom: 3.3rem;
  font-size: 3.8rem;
  line-height: 53px;
  color: #fff;
  letter-spacing: 0.07em;
}
.top-hero .top-hero-title p {
  display: flex;
  overflow: hidden;
}
.top-hero .top-hero-title p span {
  transform: translateY(2em);
}
@media screen and (min-width: 1024px) {
  .top-hero .top-hero-title {
    margin-bottom: 6.2rem;
    font-size: 6.4rem;
    line-height: 91px;
  }
}
.top-hero .top-btn-movie {
  padding: 0 0 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 22px;
  color: #fff;
  position: relative;
  border-bottom: 1px solid #fff;
  letter-spacing: 0.07em;
  opacity: 0;
}
.top-hero .top-btn-movie::after {
  content: "\e921";
  font-family: "potaicon";
  color: #fff;
  font-size: 2.4rem;
  vertical-align: middle;
  margin-left: 1rem;
}
@media screen and (min-width: 1024px) {
  .top-hero .top-btn-movie {
    font-size: 1.8rem;
  }
  .top-hero .top-btn-movie::after {
    font-size: 2.8rem;
  }
}
.top-hero .top-hero-link {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}
@media screen and (min-width: 1024px) {
  .top-hero .top-hero-link {
    display: none;
  }
}
.top-hero .top-hero-link__item {
  width: 33.3333333333%;
}
.top-hero .top-hero-link__item:nth-child(1) {
  background-color: #31a9e9;
}
.top-hero .top-hero-link__item:nth-child(2) {
  background-color: #f57a7a;
}
.top-hero .top-hero-link__item:nth-child(3) {
  background-color: #17bfa1;
}
.top-hero .top-hero-link__item a {
  padding: 0.8rem;
  display: block;
  color: #fff;
  line-height: 1;
}
.top-hero .top-hero-link__item a .icon {
  display: block;
  font-size: 2.3rem;
}
.top-hero .top-hero-link__item a .icon::after {
  top: 0;
}
.top-hero .top-hero-picture {
  height: 100%;
  position: relative;
  border-radius: 2rem;
  display: block;
  overflow: hidden;
  opacity: 0;
}
@media screen and (min-width: 1024px) {
  .top-hero .top-hero-picture {
    border-radius: 3rem;
  }
}
.top-hero .top-hero-picture::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 15.62%);
}
.top-hero .top-hero-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-hero .top-hero-bnr {
  display: none;
}
@media screen and (min-width: 1024px) {
  .top-hero .top-hero-bnr {
    display: block;
    width: 41.8rem;
    line-height: 1;
    padding: 1.2rem;
    position: fixed;
    right: 1.2rem;
    bottom: 1.2rem;
    background-color: #fff;
    border-radius: 3rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08), 0 24px 24px rgba(0, 0, 0, 0.08);
    z-index: 1;
  }
}
.top-hero .top-hero-bnr .top-hero-bnr-list {
  display: flex;
  gap: 0.8rem;
}
.top-hero .top-hero-bnr .top-hero-bnr-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
  font-size: 2.2rem;
}
.top-hero .top-hero-corner-01,
.top-hero .top-hero-corner-02,
.top-hero .top-hero-corner-03 {
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .top-hero .top-hero-corner-01,
  .top-hero .top-hero-corner-02,
  .top-hero .top-hero-corner-03 {
    width: 3rem;
    height: 3rem;
  }
}
.top-hero .top-hero-corner-01::before,
.top-hero .top-hero-corner-02::before,
.top-hero .top-hero-corner-03::before {
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 2rem 2rem 0 0 #fff;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 1024px) {
  .top-hero .top-hero-corner-01::before,
  .top-hero .top-hero-corner-02::before,
  .top-hero .top-hero-corner-03::before {
    box-shadow: 3rem 3rem 0 0 #fff;
  }
}
.top-hero .top-hero-corner-01 {
  top: 0;
  left: 0;
  transform: translateX(0) rotate(-180deg);
}
.top-hero .top-hero-corner-02 {
  left: 0;
  bottom: 0;
  transform: rotate(90deg);
}
.top-hero .top-hero-corner-03 {
  right: 0;
  bottom: 0;
}
.top-hero.is-visible .top-btn-movie {
  animation: fadeIn 1.5s cubic-bezier(0.33, 1, 0.68, 1) 3s forwards;
}
.top-hero.is-visible .top-hero-title p span {
  opacity: 0;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(1) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.1s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(2) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.2s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(3) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.3s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(4) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.4s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(5) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.5s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(6) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.6s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(7) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.7s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(8) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.8s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(9) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.9s forwards;
}
.top-hero.is-visible .top-hero-title p:not(.delay) span:nth-child(10) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 2s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(1) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.6s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(2) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.7s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(3) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.8s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(4) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 1.9s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(5) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 2s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(6) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 2.1s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(7) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 2.2s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(8) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 2.3s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(9) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 2.4s forwards;
}
.top-hero.is-visible .top-hero-title p.delay span:nth-child(10) {
  animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) 2.5s forwards;
}

.top-pickup {
  width: 100%;
  margin: 3.4rem auto 3.7rem;
  padding: 0 2rem;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .top-pickup {
    margin: 7rem auto;
    padding: 0 8.4rem;
  }
}
.top-pickup .top-pickup-title {
  margin-bottom: 1.6rem;
  padding-left: 1.6rem;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 19px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .top-pickup .top-pickup-title {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-pickup .top-pickup-title {
    margin-bottom: 2.3rem;
    padding-left: 2.4rem;
  }
}
.top-pickup .top-pickup-title::before {
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  background-color: #fff55e;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.top-pickup .top-pickup-swiper {
  overflow: visible;
  z-index: 0;
}
.top-pickup .top-pickup-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-pickup .top-pickup-head .top-pickup-swiper-button {
  display: none;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .top-pickup .top-pickup-head .top-pickup-swiper-button {
    display: flex;
    align-items: center;
  }
}
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-prev,
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-next {
  cursor: pointer;
}
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-prev::after,
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-next::after {
  font-family: "potaicon";
  font-size: 1.2rem;
}
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-prev {
  margin-right: 2rem;
}
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-prev::after {
  content: "\e934";
}
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-next {
  margin-left: 2rem;
}
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-next::after {
  content: "\e933";
}
.top-pickup .top-pickup-head .top-pickup-swiper-button .top-pickup-pagination {
  position: static;
  background-color: transparent;
  font-size: 1.3rem;
}
.top-pickup .top-pickup-item {
  width: 24.6rem;
  margin-right: 1rem;
}
@media screen and (min-width: 768px) {
  .top-pickup .top-pickup-item {
    width: 36.8rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-pickup .top-pickup-item {
    margin-right: 1.6rem;
  }
}
.top-pickup .top-pickup-item__link {
  display: block;
  border-radius: 1rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .top-pickup .top-pickup-item__link {
    border-radius: 1.6rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-article {
    margin: 8.8rem auto;
  }
}
@media screen and (min-width: 1226px) {
  .top-article .top-article-wrapper {
    display: flex;
    gap: 4.6rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-article .top-article-head {
    display: flex;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    margin-bottom: 2.2rem;
  }
  .top-article .top-article-head .content-inner {
    width: auto;
    margin: 0;
  }
}
.top-article .top-article-title {
  margin-bottom: 1.2rem;
  font-size: 2.2rem;
}
@media screen and (min-width: 768px) {
  .top-article .top-article-title {
    margin-right: 3.3rem;
    font-size: 2.6rem;
  }
}
.top-article .tab-contents {
  margin-bottom: 1.2rem;
  padding: 0;
  border: none;
}
.top-article .link {
  color: #000;
  font-size: 1.3rem;
  padding-bottom: 0;
}

.top-news {
  margin: 2.5rem auto 3.5rem;
}
@media screen and (min-width: 1226px) {
  .top-news {
    width: 50%;
    margin: 0 auto;
  }
}

.top-blog {
  margin: 2.5rem auto;
}
@media screen and (min-width: 1024px) {
  .top-blog {
    margin-top: 6rem;
  }
}
@media screen and (min-width: 1226px) {
  .top-blog {
    width: 50%;
    margin: 0 auto;
  }
}

.top-attraction {
  margin: 6rem 0.4rem 6.2rem;
  padding: 5.7rem 1.6rem 6.2rem;
  border-radius: 2rem;
  background-color: #fff55e;
}
@media screen and (min-width: 768px) {
  .top-attraction {
    padding: 8rem 5rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-attraction {
    margin: 10.8rem 1.2rem 0;
    padding: 10rem 7rem 7.3rem;
    border-radius: 3rem;
  }
}
.top-attraction .top-attraction-title {
  margin-bottom: 6.2rem;
  font-size: 6vw;
  letter-spacing: 0.02em;
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
  .top-attraction .top-attraction-title {
    font-size: 4.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-title {
    margin-bottom: 8.6rem;
    font-size: 6.2rem;
    letter-spacing: 0.03em;
  }
}
.top-attraction .top-attraction-title--min {
  margin-bottom: 1.4rem;
  font-size: 1.8rem;
  line-height: 1.25;
  display: block;
}
@media screen and (min-width: 768px) {
  .top-attraction .top-attraction-title--min {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-title--min {
    margin-bottom: 2.4rem;
    font-size: 3.6rem;
    letter-spacing: 0.03em;
  }
}
.top-attraction .top-attraction-title .textIn {
  flex-wrap: wrap;
}
.top-attraction .top-attraction-head {
  margin-bottom: 1.8rem;
  position: relative;
}
.top-attraction .top-attraction-item {
  margin: 6rem auto 0;
  position: relative;
  z-index: 0;
}
@media screen and (min-width: 768px) {
  .top-attraction .top-attraction-item:not(:nth-child(2)) {
    margin: 8rem auto 0;
  }
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-item:not(:nth-child(2)) {
    margin: 10rem auto 0;
  }
}
@media screen and (min-width: 1226px) {
  .top-attraction .top-attraction-item:not(:nth-child(2)) {
    margin: 13.4rem auto 0;
  }
}
.top-attraction .top-attraction-item__title {
  padding: 0 1.1rem 0 1.4rem;
  position: absolute;
  top: 0.3rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .top-attraction .top-attraction-item__title {
    align-items: center;
  }
}
@media screen and (min-width: 1226px) {
  .top-attraction .top-attraction-item__title {
    padding: 0 2.6rem 0 0.5rem;
    transform: translateY(0);
    align-items: start;
  }
}
@media screen and (min-width: 1226px) {
  .top-attraction .top-attraction-item__title .text {
    transform: translateY(-3.7rem);
  }
}
.top-attraction .top-attraction-item__picture {
  position: relative;
}
.top-attraction .top-attraction-item__picture a {
  display: block;
}
.top-attraction .top-attraction-item__picture img {
  width: 100%;
  border-radius: 2rem;
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-item__picture img {
    border-radius: 3rem;
  }
}
.top-attraction .top-attraction-item__picture .corner {
  width: 3.4rem;
  height: 3.4rem;
  top: auto;
  bottom: 0;
  transform: rotate(90deg);
  background-color: #fff55e;
}
.top-attraction .top-attraction-item__picture .corner--top, .top-attraction .top-attraction-item__picture .corner--bottom {
  width: 1rem;
  height: 1rem;
}
.top-attraction .top-attraction-item__picture .corner--top::before, .top-attraction .top-attraction-item__picture .corner--bottom::before {
  box-shadow: 1rem 1rem 0 0 #fff55e;
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-item__picture .corner {
    width: 7.3rem;
    height: 7.3rem;
  }
  .top-attraction .top-attraction-item__picture .corner--top, .top-attraction .top-attraction-item__picture .corner--bottom {
    width: 2rem;
    height: 2rem;
  }
  .top-attraction .top-attraction-item__picture .corner--top::before, .top-attraction .top-attraction-item__picture .corner--bottom::before {
    box-shadow: 2rem 2rem 0 0 #fff55e;
  }
}
.top-attraction .top-attraction-item__icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2.6rem;
  height: 2.6rem;
  text-align: center;
  background-color: #333;
  border-radius: 50%;
  z-index: 1;
}
.top-attraction .top-attraction-item__icon .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-item__icon {
    width: 6rem;
    height: 6rem;
    font-size: 30px;
  }
}
.top-attraction .top-attraction-item__text h4 {
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: normal;
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-item__text h4 {
    font-size: 2rem;
  }
}
.top-attraction .top-attraction-item__text h4 em {
  margin: 1.2rem 0;
  font-size: 2rem;
  display: inline-block;
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-item__text h4 em {
    margin: 1.4rem 0 1.7rem;
    font-size: 3rem;
  }
}
.top-attraction .top-attraction-item__text p {
  font-size: 1.4rem;
}
@media screen and (min-width: 1024px) {
  .top-attraction .top-attraction-item__text p {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1226px) {
  .top-attraction .top-attraction-item__text {
    padding: 0 4rem 5.8rem 10.6rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 52.2rem;
    background: #fff55e;
    border-radius: 0 0 3rem;
  }
  .top-attraction .top-attraction-item__text .corner--top::before,
  .top-attraction .top-attraction-item__text .corner--bottom::before {
    box-shadow: 2rem 2rem 0 0 #fff55e;
  }
  .top-attraction .top-attraction-item__text .corner--top {
    right: 0;
    left: auto;
    transform: translateX(100%) rotate(-180deg);
  }
  .top-attraction .top-attraction-item__text .corner--bottom {
    left: 0;
    right: auto;
    transform: translateY(100%) rotate(-180deg);
  }
}

.top-campus {
  margin: 7.3rem 0.4rem 8.4rem;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .top-campus {
    margin: 10.5rem 1.2rem 6rem;
  }
}
.top-campus h2 {
  font-size: 3.2rem;
  margin-bottom: 0;
  padding: 0 1.4rem;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .top-campus h2 {
    font-size: 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-campus h2 {
    padding: 0 7rem;
  }
}
@media screen and (min-width: 1226px) {
  .top-campus h2 {
    font-size: 9.6rem;
  }
}
.top-campus .slick-list {
  max-width: 100%;
}
.top-campus .slick-dots {
  padding: 0 1.2rem;
  text-align: left;
  position: static;
  line-height: 1;
}
@media screen and (min-width: 1024px) {
  .top-campus .slick-dots {
    padding: 0.9rem 6.9rem;
  }
}
.top-campus .slick-dots li {
  width: auto;
  height: auto;
  margin: 0 3px;
}
.top-campus .slick-dots li button {
  width: 4.7rem;
  height: 0.4rem;
  padding: 0;
  background-color: #eaeaea;
}
.top-campus .slick-dots li button::before {
  display: none;
}
.top-campus .slick-dots li.slick-active button {
  background-color: #31a9e9;
}
.top-campus .slick-slide img {
  width: 100%;
}
.top-campus .slick-slider {
  max-width: none;
}
.top-campus .top-campus-picture {
  position: relative;
}
.top-campus .top-campus-txt {
  position: absolute;
  height: 38vw;
  top: 2.2rem;
  right: 1.4rem;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-txt {
    top: 9.1rem;
    right: 6rem;
    height: 32vw;
  }
}
.top-campus .top-campus-txt img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.top-campus .top-campus-inner {
  padding: 0 1.4rem;
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-inner {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 66%;
    max-width: 98rem;
    padding: 4.3rem 6rem 0.8rem;
    border-radius: 3rem 0 0 0;
  }
}
.top-campus .top-campus-inner .top-campus-corner-01,
.top-campus .top-campus-inner .top-campus-corner-02 {
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-inner .top-campus-corner-01,
  .top-campus .top-campus-inner .top-campus-corner-02 {
    width: 3rem;
    height: 3rem;
  }
}
.top-campus .top-campus-inner .top-campus-corner-01::before,
.top-campus .top-campus-inner .top-campus-corner-02::before {
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 2rem 2rem 0 0 #fff;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-inner .top-campus-corner-01::before,
  .top-campus .top-campus-inner .top-campus-corner-02::before {
    box-shadow: 3rem 3rem 0 0 #fff;
  }
}
.top-campus .top-campus-inner .top-campus-corner-01 {
  right: 0;
  bottom: 100%;
}
.top-campus .top-campus-inner .top-campus-corner-02 {
  right: 100%;
  bottom: 19.5rem;
}
.top-campus .top-campus-slider {
  margin-top: -1.6rem;
  margin-bottom: 2.2rem;
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-slider {
    margin-top: -6.2rem;
    margin-bottom: 0;
    padding-bottom: 16rem;
  }
}
.top-campus .top-campus-slider .top-campus-slider-item {
  padding: 3rem 2rem 0 0;
  min-height: 20.6rem;
  border-radius: 2rem;
  background: linear-gradient(90.42deg, #fffdd6 0%, #f5fafc 100%);
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-slider .top-campus-slider-item {
    border-radius: 3rem;
    padding: 11rem 7.6rem 0 0;
  }
}
.top-campus .top-campus-slider .top-campus-slider-item img {
  border-radius: 0 2rem 0 0;
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-slider .top-campus-slider-item img {
    border-radius: 0 3rem 0 0;
  }
}
.top-campus .top-campus-content h3 {
  margin-bottom: 1.2rem;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1.8;
}
@media screen and (min-width: 1226px) {
  .top-campus .top-campus-content h3 {
    font-size: 3.2rem;
  }
}
.top-campus .top-campus-content p {
  font-size: 1.4rem;
}
@media screen and (min-width: 1226px) {
  .top-campus .top-campus-content p {
    font-size: 1.5rem;
  }
}
.top-campus .top-campus-content .btn-wrapper {
  margin-top: 1.8rem;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-content .btn-wrapper {
    margin-top: 3.3rem;
    text-align: left;
    justify-content: flex-start;
  }
}
.top-campus .top-campus-content .btn-wrapper .btn {
  width: 20rem;
  min-width: auto;
  font-size: 1.3rem;
  padding: 1.7rem;
}
@media screen and (min-width: 1024px) {
  .top-campus .top-campus-content .btn-wrapper .btn {
    width: 21.8rem;
    padding: 20px 25px;
    font-size: 1.5rem;
  }
}

.interview {
  margin: 6.5rem auto;
}
@media screen and (min-width: 1024px) {
  .interview {
    margin: 9.8rem auto;
  }
}
.interview h2 {
  margin-bottom: 3rem;
}
@media screen and (min-width: 1024px) {
  .interview h2 {
    margin-bottom: 4rem;
    font-size: 3.4rem;
  }
}
.interview .btn {
  width: 20rem;
  min-width: auto;
  padding: 1.8rem;
  font-size: 1.3rem;
  border-color: #ddd;
}
@media screen and (min-width: 1024px) {
  .interview .btn {
    width: 22rem;
    padding: 2rem;
    font-size: 1.5rem;
  }
}
.interview .interview-wrapper {
  margin-bottom: 1.4rem;
}
@media screen and (min-width: 1024px) {
  .interview .interview-wrapper {
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .top-page-link {
    margin: 6rem 1.2rem;
    padding: 6.6rem 7rem;
  }
}
@media screen and (min-width: 1226px) {
  .top-page-link {
    margin: 11.2rem 1.2rem 0;
  }
}
.top-page-link a.top-page-link-wrapper {
  padding: 0;
  display: flex;
  border-radius: 1rem;
}
@media screen and (min-width: 1024px) {
  .top-page-link a.top-page-link-wrapper {
    border-radius: 2rem;
  }
  .top-page-link a.top-page-link-wrapper::after {
    right: 3rem;
  }
}
.top-page-link .top-btn-list {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 0.6rem;
  grid-row-gap: 0.6rem;
}
@media screen and (min-width: 768px) {
  .top-page-link .top-btn-list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-page-link .top-btn-list {
    grid-column-gap: 1.6rem;
    grid-row-gap: 1.4rem;
  }
}
.top-page-link__picture {
  width: 8.6rem;
}
@media screen and (min-width: 1024px) {
  .top-page-link__picture {
    width: 15rem;
    height: 15.2rem;
  }
}
.top-page-link__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-page-link__content {
  padding: 1.8rem 1.3rem;
  text-align: left;
  flex: 1;
}
@media screen and (min-width: 1024px) {
  .top-page-link__content {
    padding: 3.7rem 6.8rem 2.7rem 4.8rem;
  }
}
.top-page-link__title {
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  display: inline-block;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .top-page-link__title {
    margin-bottom: 1.8rem;
    font-size: 1.6rem;
  }
}
.top-page-link__title::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #333;
}
.top-page-link__text {
  font-size: 1.2rem;
  line-height: 1.75;
}
@media screen and (min-width: 1024px) {
  .top-page-link__text {
    font-size: 1.4rem;
    letter-spacing: 0.03em;
  }
}

.top-try {
  height: 31.3rem;
  margin: 8.3rem 0.4rem 0;
  border: 1.8rem solid #fff55e;
  border-radius: 2rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .top-try {
    height: 40vw;
    max-height: 50.9rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-try {
    margin: 12rem 0 0;
    border-width: 3.2rem;
    border-radius: 3rem;
  }
}
.top-try .top-try-title {
  margin-left: 1.3rem;
  margin-bottom: 0;
  letter-spacing: -0.05em;
  font-family: "Arizonia", cursive;
  font-size: 66px;
  line-height: 34px;
  font-weight: normal;
  transform: matrix(0.99, -0.12, 0.12, 0.99, 0, 0);
  position: absolute;
  top: -3rem;
  left: -1.8rem;
}
@media screen and (min-width: 768px) {
  .top-try .top-try-title {
    top: -1rem;
    left: -1rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-try .top-try-title {
    top: 0;
    left: -0.2rem;
    font-size: 12.4rem;
  }
}
.top-try .top-try-inner {
  width: calc(100% + 3.6rem);
  height: 31.3rem;
  padding: 6rem 4.2rem;
  position: absolute;
  top: -3.6rem;
  left: -1.8rem;
  background: url(/images/index/bg_try_sp.png) no-repeat center 4px/94%;
}
@media screen and (min-width: 768px) {
  .top-try .top-try-inner {
    height: calc(40vw + 3.8rem);
    max-height: 50.9rem;
    padding-top: 14%;
    padding-left: 8%;
    background: url(/images/index/bg_try.png) no-repeat center 0/86%;
  }
}
@media screen and (min-width: 1024px) {
  .top-try .top-try-inner {
    padding-top: 15.5%;
    top: -6rem;
    background: url(/images/index/bg_try.png) no-repeat 18px -22px/99%;
  }
}
@media screen and (min-width: 768px) {
  .top-try .top-try-content {
    display: flex;
  }
}
.top-try .top-try-text {
  font-size: 1.6rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .top-try .top-try-text {
    text-align: left;
  }
}
@media screen and (min-width: 1024px) {
  .top-try .top-try-text {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1226px) {
  .top-try .top-try-text {
    font-size: 2.4rem;
  }
}
.top-try .top-try-picture {
  width: 57.5vw;
  max-width: 22rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .top-try .top-try-picture {
    width: 40%;
    max-width: 50rem;
    margin: 0 auto;
    position: absolute;
    right: 10vw;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 1024px) {
  .top-try .top-try-picture {
    width: 43%;
    right: 3.5%;
    top: 57%;
  }
  .top-try .top-try-picture img {
    width: 100%;
  }
}
.top-try .top-try-btn {
  position: absolute;
  display: flex;
  bottom: 2.3rem;
  left: -0.9rem;
  width: calc(100% + 1.8rem);
  gap: 8px;
  text-align: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .top-try .top-try-btn {
    width: 100%;
    padding: 0 6%;
    left: 0;
    bottom: 5rem;
    gap: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .top-try .top-try-btn {
    bottom: 6.7rem;
  }
}
.top-try .top-try-btn li {
  width: 50%;
  max-width: 16.5rem;
}
@media screen and (min-width: 768px) {
  .top-try .top-try-btn li {
    max-width: none;
  }
}
.top-try .top-try-btn li a {
  padding: 2.5rem 1.4rem;
  display: block;
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 1rem;
  line-height: 1;
  box-shadow: 0px 0px 3.04248px rgba(0, 0, 0, 0.08), 0px 7.30195px 7.30195px rgba(0, 0, 0, 0.08);
}
@media screen and (min-width: 1024px) {
  .top-try .top-try-btn li a {
    padding: 5rem 2rem;
    border-radius: 3rem;
    font-size: 2.2rem;
  }
}
.top-try .top-try-btn li:nth-child(1) a {
  background-color: #31a9e9;
}
.top-try .top-try-btn li:nth-child(2) a {
  background-color: #ffa9a9;
}

#modal-concept .youtube {
  width: 100%;
  aspect-ratio: 9/16;
  border-radius: 1.6rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  #modal-concept .youtube {
    aspect-ratio: 16/9;
  }
}
#modal-concept .youtube iframe {
  width: 100%;
  height: 100%;
}

::-webkit-full-page-media,
:future,
:root .top-campus .top-campus-txt {
  right: 0;
}
@media screen and (min-width: 1024px) {
  ::-webkit-full-page-media,
  :future,
  :root .top-campus .top-campus-txt {
    right: 6rem;
  }
}