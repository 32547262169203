@charset 'UTF-8';
@use '../global/' as *;

#video-area {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1; /*最背面に設定*/
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 0.01%, rgba(0, 0, 0, 0.1) 30.33%);
  }
}

#video {
  /*天地中央配置*/
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*縦横幅指定*/
  width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
  height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
  min-height: 100%;
  min-width: 100%;
}

// common
.top-box {
  padding: 1.6rem;
  background: linear-gradient(90.42deg, $primary-95 0%, #f5fafc 100%);
  border-radius: 1.2rem;
  margin: 0 0.4rem 0.4rem;
  @include media(lg) {
    margin: 0 1.2rem 0.4rem;
    padding: 3rem;
    border-radius: 3rem;
  }
  .top-btn-list {
    display: grid;
    line-height: 1;
    a {
      padding: 1.7rem 0;
      display: block;
      border: 1px solid $base;
      border-radius: 0.8rem;
      background-color: $white;
      overflow: hidden;
      letter-spacing: 0.07em;
      @include media(xl) {
        padding: 2.7rem 5rem 2.7rem 14px;
        border-radius: 1rem;
        position: relative;
        &::after {
          content: $arrow-right;
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 50%;
          font-family: $icon-font-family;
          background-color: $primary;
          position: absolute;
          top: 50%;
          right: 1.6rem;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &--head {
    @include media(lg) {
      padding: 3rem 8.4rem;
    }
    .top-btn-list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;
      text-align: center;
      font-size: 1.3rem;
      line-height: 1;
      @include media(lg) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 1.2rem;
        font-size: 1.6rem;
      }
    }
  }
}
.top-title {
  &--en {
    font-family: $base-font-family-c;
  }
}
.top-inner {
  @include media(lg) {
    max-width: calc(1200px + 2.4rem);
    margin: 0 auto;
    padding: 0 1.2rem;
  }
}

// hero
.top-hero {
  height: 100vh;
  position: relative;
  z-index: 2;
  .top-hero-inner {
    height: 100vh;
    margin: 0;
    border: 0.4rem solid $white;
    position: relative;
    @include media(lg) {
      border-width: 1.2rem;
    }
  }
  .top-hero-content {
    width: 100%;
    padding: 0 2.7rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-30%);
    width: 100%;
    @include media(lg) {
      padding: 0 7rem;
    }
  }
  .top-hero-title {
    margin-bottom: 3.3rem;
    font-size: 3.8rem;
    line-height: 53px;
    color: $white;
    letter-spacing: 0.07em;
    p {
      display: flex;
      overflow: hidden;
      span {
        transform: translateY(2em);
      }
    }
    @include media(lg) {
      margin-bottom: 6.2rem;
      font-size: 6.4rem;
      line-height: 91px;
    }
  }
  .top-btn-movie {
    padding: 0 0 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 22px;
    color: $white;
    position: relative;
    border-bottom: 1px solid $white;
    letter-spacing: 0.07em;
    opacity: 0;
    &::after {
      content: $c-movie;
      font-family: $icon-font-family;
      color: $white;
      font-size: 2.4rem;
      vertical-align: middle;
      margin-left: 1rem;
    }
    @include media(lg) {
      font-size: 1.8rem;
      &::after {
        font-size: 2.8rem;
      }
    }
  }
  .top-hero-link {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    @include media(lg) {
      display: none;
    }
    &__item {
      width: calc(100% / 3);
      &:nth-child(1) {
        background-color: $secondary;
      }
      &:nth-child(2) {
        background-color: $tertiary-50;
      }
      &:nth-child(3) {
        background-color: #17bfa1;
      }
      a {
        padding: 0.8rem;
        display: block;
        color: $white;
        line-height: 1;
        .icon {
          display: block;
          font-size: 2.3rem;
          &::after {
            top: 0;
          }
        }
      }
    }
  }
  .top-hero-picture {
    height: 100%;
    position: relative;
    border-radius: 2rem;
    display: block;
    overflow: hidden;
    opacity: 0;
    @include media(lg) {
      border-radius: 3rem;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 15.62%);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .top-hero-bnr {
    display: none;
    @include media(lg) {
      display: block;
      width: 41.8rem;
      line-height: 1;
      padding: 1.2rem;
      position: fixed;
      right: 1.2rem;
      bottom: 1.2rem;
      background-color: $white;
      border-radius: 3rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08), 0 24px 24px rgba(0, 0, 0, 0.08);
      z-index: 1;
    }
    .top-hero-bnr-list {
      display: flex;
      gap: 0.8rem;
    }
    .top-hero-bnr-close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-50%);
      font-size: 2.2rem;
    }
  }
  .top-hero-corner-01,
  .top-hero-corner-02,
  .top-hero-corner-03 {
    display: block;
    width: 2rem;
    height: 2rem;
    position: absolute;
    overflow: hidden;
    @include media(lg) {
      width: 3rem;
      height: 3rem;
    }
    &::before {
      content: '';
      display: block;
      width: 200%;
      height: 200%;
      position: absolute;
      top: 0px;
      left: 0px;
      background: transparent;
      border-radius: 50%;
      box-shadow: 2rem 2rem 0 0 $white;
      transform: translate(-50%, -50%);
      @include media(lg) {
        box-shadow: 3rem 3rem 0 0 $white;
      }
    }
  }
  .top-hero-corner-01 {
    top: 0;
    left: 0;
    transform: translateX(0) rotate(-180deg);
  }
  .top-hero-corner-02 {
    left: 0;
    bottom: 0;
    transform: rotate(90deg);
  }
  .top-hero-corner-03 {
    right: 0;
    bottom: 0;
  }

  &.is-visible {
    .top-btn-movie {
      animation: fadeIn 1.5s cubic-bezier(0.33, 1, 0.68, 1) 3s forwards;
    }
    .top-hero-title {
      p {
        span {
          opacity: 0;
        }
        &:not(.delay) {
          span {
            $delay: 0.1;
            @for $i from 1 through 10 {
              &:nth-child(#{$i}) {
                animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) (1 + $delay * $i + s) forwards;
              }
            }
          }
        }
        &.delay {
          span {
            $delay: 0.1;
            @for $i from 1 through 10 {
              &:nth-child(#{$i}) {
                animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) (1.5 + $delay * $i + s) forwards;
              }
            }
          }
        }
      }
    }
  }
}

// PICK UP
.top-pickup {
  width: 100%;
  margin: 3.4rem auto 3.7rem;
  padding: 0 2rem;
  overflow: hidden;
  @include media(lg) {
    margin: 7rem auto;
    padding: 0 8.4rem;
  }
  .top-pickup-title {
    margin-bottom: 1.6rem;
    padding-left: 1.6rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 19px;
    position: relative;
    @include media(md) {
      font-size: 2.2rem;
    }
    @include media(lg) {
      margin-bottom: 2.3rem;
      padding-left: 2.4rem;
    }
    &::before {
      content: '';
      width: 0.8rem;
      height: 0.8rem;
      background-color: $primary;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  .top-pickup-swiper {
    overflow: visible;
    z-index: 0;
  }
  .top-pickup-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-pickup-swiper-button {
      display: none;
      position: relative;
      @include media(lg) {
        display: flex;
        align-items: center;
      }
      .top-pickup-prev,
      .top-pickup-next {
        cursor: pointer;
        &::after {
          font-family: $icon-font-family;
          font-size: 1.2rem;
        }
      }
      .top-pickup-prev {
        margin-right: 2rem;
        &::after {
          content: $c-chevron-left;
        }
      }
      .top-pickup-next {
        margin-left: 2rem;
        &::after {
          content: $c-chevron-right;
        }
      }
      .top-pickup-pagination {
        position: static;
        background-color: transparent;
        font-size: 1.3rem;
      }
    }
  }
  .top-pickup-item {
    width: 24.6rem;
    margin-right: 1rem;
    @include media(md) {
      width: 36.8rem;
    }
    @include media(lg) {
      margin-right: 1.6rem;
    }
    &__link {
      display: block;
      border-radius: 1rem;
      overflow: hidden;
      @include media(md) {
        border-radius: 1.6rem;
      }
    }
    &__picture {
    }
  }
}

// ニュース・ブログ
.top-article {
  @include media(lg) {
    margin: 8.8rem auto;
  }
  .top-article-wrapper {
    @include media(xl) {
      display: flex;
      gap: 4.6rem;
    }
  }
  .top-article-head {
    @include media(lg) {
      display: flex;
      border-bottom: 1px solid $border-color;
      margin-bottom: 2.2rem;
      .content-inner {
        width: auto;
        margin: 0;
      }
    }
  }
  .top-article-title {
    margin-bottom: 1.2rem;
    font-size: 2.2rem;
    @include media(md) {
      margin-right: 3.3rem;
      font-size: 2.6rem;
    }
  }
  .tab-contents {
    margin-bottom: 1.2rem;
    padding: 0;
    border: none;
  }
  .link {
    color: $black;
    font-size: 1.3rem;
    padding-bottom: 0;
  }
}

// NEWS
.top-news {
  margin: 2.5rem auto 3.5rem;
  @include media(xl) {
    width: 50%;
    margin: 0 auto;
  }
}

// BLOG
.top-blog {
  margin: 2.5rem auto;
  @include media(lg) {
    margin-top: 6rem;
  }
  @include media(xl) {
    width: 50%;
    margin: 0 auto;
  }
}

// 横浜中央看護って何がいいの？
.top-attraction {
  margin: 6rem 0.4rem 6.2rem;
  padding: 5.7rem 1.6rem 6.2rem;
  border-radius: 2rem;
  background-color: $primary;
  @include media(md) {
    padding: 8rem 5rem;
  }
  @include media(lg) {
    margin: 10.8rem 1.2rem 0;
    padding: 10rem 7rem 7.3rem;
    border-radius: 3rem;
  }
  .top-attraction-title {
    margin-bottom: 6.2rem;
    font-size: 6vw;
    letter-spacing: 0.02em;
    line-height: 1.4;
    @include media(md) {
      font-size: 4.5rem;
    }
    @include media(lg) {
      margin-bottom: 8.6rem;
      font-size: 6.2rem;
      letter-spacing: 0.03em;
    }
    &--min {
      margin-bottom: 1.4rem;
      font-size: 1.8rem;
      line-height: 1.25;
      display: block;
      @include media(md) {
        font-size: 2.4rem;
      }
      @include media(lg) {
        margin-bottom: 2.4rem;
        font-size: 3.6rem;
        letter-spacing: 0.03em;
      }
    }
    .textIn {
      flex-wrap: wrap;
    }
  }
  .top-attraction-head {
    margin-bottom: 1.8rem;
    position: relative;
  }
  .top-attraction-item {
    margin: 6rem auto 0;
    position: relative;
    z-index: 0;
    @include media(md) {
      &:not(:nth-child(2)) {
        margin: 8rem auto 0;
      }
    }
    @include media(lg) {
      &:not(:nth-child(2)) {
        margin: 10rem auto 0;
      }
    }
    @include media(xl) {
      &:not(:nth-child(2)) {
        margin: 13.4rem auto 0;
      }
    }
    &__title {
      padding: 0 1.1rem 0 1.4rem;
      position: absolute;
      top: 0.3rem;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      transform: translateY(-50%);
      z-index: 1;
      @include media(md) {
        align-items: center;
      }
      @include media(xl) {
        padding: 0 2.6rem 0 0.5rem;
        transform: translateY(0);
        align-items: start;
      }
      .text {
        @include media(xl) {
          transform: translateY(-3.7rem);
        }
      }
    }
    &__picture {
      position: relative;
      a {
        display: block;
      }
      img {
        width: 100%;
        border-radius: 2rem;
        @include media(lg) {
          border-radius: 3rem;
        }
      }
      .corner {
        width: 3.4rem;
        height: 3.4rem;
        top: auto;
        bottom: 0;
        transform: rotate(90deg);
        background-color: $primary;
        &--top,
        &--bottom {
          width: 1rem;
          height: 1rem;
          &::before {
            box-shadow: 1rem 1rem 0 0 $primary;
          }
        }
        @include media(lg) {
          width: 7.3rem;
          height: 7.3rem;
          &--top,
          &--bottom {
            width: 2rem;
            height: 2rem;
            &::before {
              box-shadow: 2rem 2rem 0 0 $primary;
            }
          }
        }
      }
    }
    &__icon {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 2.6rem;
      height: 2.6rem;
      text-align: center;
      background-color: $base;
      border-radius: 50%;
      z-index: 1;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      @include media(lg) {
        width: 6rem;
        height: 6rem;
        font-size: 30px;
      }
    }
    &__text {
      h4 {
        margin-bottom: 0;
        font-size: 1.6rem;
        font-weight: normal;
        @include media(lg) {
          font-size: 2rem;
        }
        em {
          margin: 1.2rem 0;
          font-size: 2rem;
          display: inline-block;
          @include media(lg) {
            margin: 1.4rem 0 1.7rem;
            font-size: 3rem;
          }
        }
      }
      p {
        font-size: 1.4rem;
        @include media(lg) {
          font-size: 1.5rem;
        }
      }
      @include media(xl) {
        padding: 0 4rem 5.8rem 10.6rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 52.2rem;
        background: $primary;
        border-radius: 0 0 3rem;
        .corner--top,
        .corner--bottom {
          &::before {
            box-shadow: 2rem 2rem 0 0 $primary;
          }
        }
        .corner--top {
          right: 0;
          left: auto;
          transform: translateX(100%) rotate(-180deg);
        }
        .corner--bottom {
          left: 0;
          right: auto;
          transform: translateY(100%) rotate(-180deg);
        }
      }
    }
  }
}

// オープンキャンパス
.top-campus {
  margin: 7.3rem 0.4rem 8.4rem;
  position: relative;
  @include media(lg) {
    margin: 10.5rem 1.2rem 6rem;
  }
  h2 {
    font-size: 3.2rem;
    margin-bottom: 0;
    padding: 0 1.4rem;
    position: relative;
    z-index: 1;
    @include media(md) {
      font-size: 6rem;
    }
    @include media(lg) {
      padding: 0 7rem;
    }
    @include media(xl) {
      font-size: 9.6rem;
    }
  }
  .slick-list {
    max-width: 100%;
  }
  .slick-dots {
    padding: 0 1.2rem;
    text-align: left;
    position: static;
    line-height: 1;
    @include media(lg) {
      padding: 0.9rem 6.9rem;
    }
    li {
      width: auto;
      height: auto;
      margin: 0 3px;
      button {
        width: 4.7rem;
        height: 0.4rem;
        padding: 0;
        background-color: #eaeaea;
        &::before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background-color: $secondary;
        }
      }
    }
  }
  .slick-slide {
    img {
      width: 100%;
    }
  }
  .slick-slider {
    max-width: none;
  }
  .top-campus-picture {
    position: relative;
  }
  .top-campus-txt {
    position: absolute;
    height: 38vw;
    top: 2.2rem;
    right: 1.4rem;
    z-index: 1;

    @include media(lg) {
      top: 9.1rem;
      right: 6rem;
      height: 32vw;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .top-campus-inner {
    padding: 0 1.4rem;
    @include media(lg) {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $white;
      width: 66%;
      max-width: 98rem;
      padding: 4.3rem 6rem 0.8rem;
      border-radius: 3rem 0 0 0;
    }
    .top-campus-corner-01,
    .top-campus-corner-02 {
      display: block;
      width: 2rem;
      height: 2rem;
      position: absolute;
      overflow: hidden;
      @include media(lg) {
        width: 3rem;
        height: 3rem;
      }
      &::before {
        content: '';
        display: block;
        width: 200%;
        height: 200%;
        position: absolute;
        top: 0px;
        left: 0px;
        background: transparent;
        border-radius: 50%;
        box-shadow: 2rem 2rem 0 0 $white;
        transform: translate(-50%, -50%);
        @include media(lg) {
          box-shadow: 3rem 3rem 0 0 $white;
        }
      }
    }
    .top-campus-corner-01 {
      right: 0;
      bottom: 100%;
    }
    .top-campus-corner-02 {
      right: 100%;
      bottom: 19.5rem;
    }
  }
  .top-campus-slider {
    margin-top: -1.6rem;
    margin-bottom: 2.2rem;
    @include media(lg) {
      margin-top: -6.2rem;
      margin-bottom: 0;
      padding-bottom: 16rem;
    }
    .top-campus-slider-item {
      padding: 3rem 2rem 0 0;
      min-height: 20.6rem;
      border-radius: 2rem;
      background: linear-gradient(90.42deg, #fffdd6 0%, #f5fafc 100%);
      overflow: hidden;
      @include media(lg) {
        border-radius: 3rem;
        padding: 11rem 7.6rem 0 0;
      }
      img {
        border-radius: 0 2rem 0 0;
        @include media(lg) {
          border-radius: 0 3rem 0 0;
        }
      }
    }
  }
  .top-campus-content {
    h3 {
      margin-bottom: 1.2rem;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 1.8;
      @include media(xl) {
        font-size: 3.2rem;
      }
    }
    p {
      font-size: 1.4rem;
      @include media(xl) {
        font-size: 1.5rem;
      }
    }
    .btn-wrapper {
      margin-top: 1.8rem;
      justify-content: center;
      @include media(lg) {
        margin-top: 3.3rem;
        text-align: left;
        justify-content: flex-start;
      }
      .btn {
        width: 20rem;
        min-width: auto;
        font-size: 1.3rem;
        padding: 1.7rem;
        @include media(lg) {
          width: 21.8rem;
          padding: 20px 25px;
          font-size: 1.5rem;
        }
      }
    }
  }
}

.interview {
  margin: 6.5rem auto;
  @include media(lg) {
    margin: 9.8rem auto;
  }
  h2 {
    margin-bottom: 3rem;
    @include media(lg) {
      margin-bottom: 4rem;
      font-size: 3.4rem;
    }
  }
  .btn {
    width: 20rem;
    min-width: auto;
    padding: 1.8rem;
    font-size: 1.3rem;
    border-color: #ddd;
    @include media(lg) {
      width: 22rem;
      padding: 2rem;
      font-size: 1.5rem;
    }
  }
  .interview-wrapper {
    margin-bottom: 1.4rem;
    @include media(lg) {
      margin-bottom: 4rem;
    }
  }
}

// リンクリスト
.top-page-link {
  @include media(lg) {
    margin: 6rem 1.2rem;
    padding: 6.6rem 7rem;
  }
  @include media(xl) {
    margin: 11.2rem 1.2rem 0;
  }
  a.top-page-link-wrapper {
    padding: 0;
    display: flex;
    border-radius: 1rem;
    @include media(lg) {
      border-radius: 2rem;
      &::after {
        right: 3rem;
      }
    }
  }
  .top-btn-list {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 0.6rem;
    grid-row-gap: 0.6rem;
    @include media(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
    }
    @include media(lg) {
      grid-column-gap: 1.6rem;
      grid-row-gap: 1.4rem;
    }
  }
  &__picture {
    width: 8.6rem;
    @include media(lg) {
      width: 15rem;
      height: 15.2rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    padding: 1.8rem 1.3rem;
    text-align: left;
    flex: 1;
    @include media(lg) {
      padding: 3.7rem 6.8rem 2.7rem 4.8rem;
    }
  }
  &__title {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    display: inline-block;
    position: relative;
    @include media(lg) {
      margin-bottom: 1.8rem;
      font-size: 1.6rem;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      border-bottom: 1px solid $base;
    }
  }
  &__text {
    font-size: 1.2rem;
    line-height: 1.75;
    @include media(lg) {
      font-size: 1.4rem;
      letter-spacing: 0.03em;
    }
  }
}

// Let’s Try!
.top-try {
  height: 31.3rem;
  margin: 8.3rem 0.4rem 0;
  border: 1.8rem solid $primary;
  border-radius: 2rem;
  position: relative;
  @include media(md) {
    height: 40vw;
    max-height: 50.9rem;
  }
  @include media(lg) {
    margin: 12rem 0 0;
    border-width: 3.2rem;
    border-radius: 3rem;
  }
  .top-try-title {
    margin-left: 1.3rem;
    margin-bottom: 0;
    letter-spacing: -0.05em;
    font-family: 'Arizonia', cursive;
    font-size: 66px;
    line-height: 34px;
    font-weight: normal;
    transform: matrix(0.99, -0.12, 0.12, 0.99, 0, 0);
    position: absolute;
    top: -3rem;
    left: -1.8rem;
    @include media(md) {
      top: -1rem;
      left: -1rem;
    }
    @include media(lg) {
      top: 0;
      left: -0.2rem;
      font-size: 12.4rem;
    }
  }
  .top-try-inner {
    width: calc(100% + 3.6rem);
    height: 31.3rem;
    padding: 6rem 4.2rem;
    position: absolute;
    top: -3.6rem;
    left: -1.8rem;
    background: url(/images/index/bg_try_sp.png) no-repeat center 4px / 94%;
    @include media(md) {
      height: calc(40vw + 3.8rem);
      max-height: 50.9rem;
      padding-top: 14%;
      padding-left: 8%;
      background: url(/images/index/bg_try.png) no-repeat center 0 / 86%;
    }
    @include media(lg) {
      padding-top: 15.5%;
      top: -6rem;
      background: url(/images/index/bg_try.png) no-repeat 18px -22px/99%;
    }
  }
  .top-try-wrapper {
  }
  .top-try-content {
    @include media(md) {
      display: flex;
    }
  }
  .top-try-text {
    font-size: 1.6rem;
    text-align: center;
    @include media(md) {
      text-align: left;
    }
    @include media(lg) {
      font-size: 2rem;
    }
    @include media(xl) {
      font-size: 2.4rem;
    }
  }
  .top-try-picture {
    width: 57.5vw;
    max-width: 22rem;
    margin: 0 auto;
    @include media(md) {
      width: 40%;
      max-width: 50rem;
      margin: 0 auto;
      position: absolute;
      right: 10vw;
      top: 50%;
      transform: translateY(-50%);
    }
    @include media(lg) {
      width: 43%;
      right: 3.5%;
      top: 57%;
      img {
        width: 100%;
      }
    }
  }
  .top-try-btn {
    position: absolute;
    display: flex;
    bottom: 2.3rem;
    left: -0.9rem;
    width: calc(100% + 1.8rem);
    gap: 8px;
    text-align: center;
    justify-content: center;
    @include media(md) {
      width: 100%;
      padding: 0 6%;
      left: 0;
      bottom: 5rem;
      gap: 16px;
    }
    @include media(lg) {
      bottom: 6.7rem;
    }
    li {
      width: 50%;
      max-width: 16.5rem;
      @include media(md) {
        max-width: none;
      }
      a {
        padding: 2.5rem 1.4rem;
        display: block;
        color: $white;
        font-size: 1.4rem;
        font-weight: bold;
        border-radius: 1rem;
        line-height: 1;
        box-shadow: 0px 0px 3.04248px rgba(0, 0, 0, 0.08), 0px 7.30195px 7.30195px rgba(0, 0, 0, 0.08);
        @include media(lg) {
          padding: 5rem 2rem;
          border-radius: 3rem;
          font-size: 2.2rem;
        }
      }
      &:nth-child(1) {
        a {
          background-color: $secondary;
        }
      }
      &:nth-child(2) {
        a {
          background-color: $tertiary;
        }
      }
    }
  }
}

#modal-concept {
  // Youtube
  .youtube {
    width: 100%;
    aspect-ratio: 9 / 16;
    border-radius: 1.6rem;
    overflow: hidden;
    @include media(md) {
      aspect-ratio: 16 / 9;
    }
  }
  .youtube iframe {
    width: 100%;
    height: 100%;
  }
}

//safariのズレ対策
::-webkit-full-page-media,
:future,
:root .top-campus .top-campus-txt {
  right: 0;
  @include media(lg) {
    right: 6rem;
  }
}
